import { SUBSCRIPTION_PLAN } from '@repo/api/payment';
import { useMonetizationFlagGetter } from '@repo/common/services/features-book';
import { MonetizationFlagName } from '@repo/common/services/features-book';
import { useViewerSubscriptionPlan } from '../../../entities/user/queries';

export const useAllowedCreditFlow = () => {
  const subscriptionPlan = useViewerSubscriptionPlan();
  const monetizationFlag = useMonetizationFlagGetter();

  if (monetizationFlag() === MonetizationFlagName.Default) {
    return true;
  }

  return subscriptionPlan !== SUBSCRIPTION_PLAN.BASIC;
};
