import { createEventEmitter } from '@repo/common/services/event-emitter';
import { createStorage } from '@repo/common/services/storage';
import type { Nullable } from '@repo/common/types/helpers';

export interface IPaymentStorageValue {
  epochStatusPayment: Nullable<'success' | 'fail'>;
}

const KEY = 'payment';

export interface IPaymentStorage {
  [KEY]: IPaymentStorageValue;
}

export interface IPaymentStorageListeners {
  onChange: Partial<IPaymentStorageValue>;
}

const eventEmitter = createEventEmitter<IPaymentStorageListeners>();
const storage = createStorage<IPaymentStorage>(window.localStorage);

const defaultValues: IPaymentStorageValue = {
  epochStatusPayment: null,
};

const getters = {
  getStorage() {
    return storage.getItem(KEY, defaultValues);
  },
  getEpochStatusPayment() {
    return this.getStorage().epochStatusPayment;
  },
};

const setters = {
  setStorage(chat: Partial<IPaymentStorageValue>) {
    const val = getters.getStorage();
    storage.setItem(KEY, { ...val, ...chat });
    eventEmitter.emit('onChange', chat);
  },
  setEpochStatusPayment(status: IPaymentStorageValue['epochStatusPayment']) {
    this.setStorage({ epochStatusPayment: status });
  },
};

const listeners = {
  onChange(callback: (value: Partial<IPaymentStorageValue>) => void) {
    eventEmitter.on('onChange', callback);
    return () => eventEmitter.off('onChange', callback);
  },
  onChangeStorage(callback: (value: IPaymentStorageValue) => void) {
    const listener = (e: StorageEvent) => {
      if (e.key === KEY) {
        callback(JSON.parse(e.newValue || '{}'));
      }
    };
    window.addEventListener('storage', listener);

    return () => window.removeEventListener('storage', listener);
  },
};

export const paymentStorage = {
  ...getters,
  ...setters,
  ...listeners,
};
